import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline"

// import {
//   MdHealthAndSafe,
//   CgTimelapse,
//   BsBank,
//   IoMdCash,
// } from "@react-icons/all-files"
import { MdHealthAndSafety } from "react-icons/md"
import { CgTimelapse } from "react-icons/cg"
import { BsBank } from "react-icons/bs"
import { IoMdCash } from "react-icons/io"

import Layout from "../components/layout"
import Seo from "../components/seo"

const features = [
  {
    name: "No cost Expert Medicare Advice",
    description:
      "We never charge a fee for our services. Medicare plans cost you the same whether you use a professional or not - so why not take advantage of our expertise.",
    icon: GlobeAltIcon,
  },
  {
    name: "Plan Options to fit your needs",
    description:
      "There can be a lot of plan options but the insurance companies will only show you theirs. We will show you all the top options to find one that fits you.",
    icon: ScaleIcon,
  },
  {
    name: "Experience you can trust",
    description:
      "Medicare can be complex and confusing. We have the experience to help you navigate the system and get the benefits you are entitled.",
    icon: LightningBoltIcon,
  },
  {
    name: "Here when you need us",
    description:
      "We are a family organization and we treat our clients like extensions of the family. We are available throughout the year to assist you in using your benefits.",
    icon: AnnotationIcon,
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="Mickle Senior Benefits | Medicare Help" />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p> */}
    {/* Hero section */}
    <div className="grid grid-cols-none w-full">
      <div className="">
        {/* <div className="md:relative"> */}
        <StaticImage
          src="../images/Hero_top_XL.jpg"
          as="div"
          // className="md:absolute left-0 top-0 md:h-[28.7rem] 2xl:h-[21.4rem] overflow-hidden -z-10 w-full object-cover object-center"
          className="md:relative md:h-[38rem] lg:h-[32.5rem] xl:h-[27rem] 2xl:h-[27rem] overflow-hidden -z-10 w-full object-cover object-top"
          loading="lazy"
          layout="fullWidth"
          fit="cover"
          apectRatio={16 / 9}
          //transformOptions={cropFocus: ATTENTION}
          //width={300}
          //quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
          //style={{ marginBottom: `1.45rem` }}
        />
      </div>

      {/* <div className="md:sticky md:w-[30rem] md:mb-10 md:ml-8 lg:ml-16 xl:ml-36 2xl:mx-auto 2xl:w-[45rem] p-2 md:p-5 z-10 bg-indigo-100/50 backdrop-blur-md flex flex-col gap-1.5 items-center justify-center border md:rounded-md md:shadow"> */}
      <div className="md:absolute left-0 right-0 sm:flex sm:flex-row max-w-screen-xl mx-auto h-full">
        <div className="lg:basis-5/12 sm:basis-6/12 z-10 md:my-2 md:ml-5 lg:ml-10 xl:ml-16">
          {/* <div className="md:w-[30rem] md:mb-10 md:ml-8 lg:ml-16 xl:ml-36 2xl:mx-auto 2xl:w-[45rem] z-10 bg-indigo-100/50 backdrop-blur-md "> */}
          {/* <div className="md:absolute left-0 right-0 md:w-[30rem] md:mb-10 md:ml-8 lg:ml-16 xl:ml-36 2xl:mx-auto 2xl:w-[45rem] z-10 bg-indigo-100/50 backdrop-blur-md "> */}
          <div className="p-2 md:p-5 flex flex-col gap-1.5 items-center justify-center border bg-indigo-100/50 backdrop-blur-md md:rounded-md md:shadow">
            <p className="text-3xl uppercase font-bold text-center ">
              Need professional Medicare advice?
            </p>
            <p className="text-2xl font-semibold text-center">
              Let us take the guesswork out of Medicare
            </p>
            <p className="text-l font-normal text-center">
              We are licensed, independent agents who specialize in Medicare. We
              use our experience to help you navigate through the Medicare maze
              and make informed decisions.{" "}
            </p>
            <p className="text-l font-normal text-center">
              We represent the top companies but we don't work for them - we
              only work for our clients.
            </p>
            <div
              role="separator"
              className="border-t-2 border-slate-800 my-2 w-full"
            ></div>
            <p className="text-2xl uppercase font-semibold text-center">
              We offer no cost Medicare Help
            </p>
            <div className="mt-3 justify-center inline-flex rounded-md shadow">
              <Link
                to="/gethelp"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
              >
                Get help now
              </Link>
            </div>
          </div>
        </div>
        <div className="lg:basis-7/12 sm:basis-6/12 z-10 h-full">
          <div className="space-y-2 lg:space-y-4 lg:px-6 py-2 px-3 md:bg-transparent bg-red-900/40">
            <h3 className="text-center px-4 py-2 animate-fadein1 text-white text-shadow bg-blue-600/50 font-bold text-2xl">
              Getting expert Medicare advice costs you nothing
            </h3>

            <div className="animate-fadein2 text-white text-shadow font-bold text-xl md:text-2xl flex gap-x-2 px-5">
              <div className="">
                {/* <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-400 text-white"> */}

                <MdHealthAndSafety
                  className="h-8 w-8 bg-red-900/40 rounded-full p-1"
                  aria-hidden="true"
                />
              </div>

              <p>We work with you find the exactly the right Medicare plan</p>
            </div>

            <div className="animate-fadein3 text-white text-shadow font-bold text-xl md:text-2xl flex gap-x-2 px-5">
              <div className="">
                <CgTimelapse
                  className="h-8 w-8 bg-red-900/40 rounded-full p-1"
                  aria-hidden="true"
                />
              </div>
              <p>
                We are available year-round to answer questions or assist with
                claims and billing issues
              </p>
            </div>

            <div className="animate-fadein4 text-white text-shadow font-bold text-xl md:text-2xl flex gap-x-2 px-5">
              <div className="">
                <BsBank
                  className="h-8 w-8 bg-red-900/40 rounded-full p-1"
                  aria-hidden="true"
                />
              </div>
              <p>
                Any monthly premium is paid directly to the carrier / government
              </p>
            </div>

            <div className="animate-fadein5 text-white text-shadow font-bold text-xl md:text-2xl flex gap-x-2 px-5">
              <div className="">
                <IoMdCash
                  className="h-8 w-8 bg-red-900/40 rounded-full p-1"
                  aria-hidden="true"
                />
              </div>
              <p>
                Your premium is the same whether you use our services or enroll
                direct
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Plan Type Information Grid */}
    {/* <div className="px-4 py-6 bg-slate-200 flex flex-row gap-3 items-start justify-center">
      <div className="flex flex-col gap-2 items-center justify-start"> */}
    <div className="max-w-screen-xl mx-auto px-4">
      {/* mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-4"> */}
      <div className="flex flex-wrap gap-y-3 lg:gap-y-0">
        <div className="w-full flex flex-col px-1 sm:w-1/2 lg:w-1/3">
          <div className="flex flex-col flex-1 px-8 py-10 bg-white rounded-lg shadow-lg">
            <div className="flex-1">
              <h2 className="text-center text-xl uppercase font-bold">
                Medicare Supplement Plans
              </h2>
              <p className="mt-5 text-base text-center">
                Medicare Supplement Plans, sometimes called Medigap or MedSupps,
                work with Original Medicare and pay some or all of Original
                Medicare's deductibles and coinsurance depending on the Plan
                Type. They do not include prescription drug coverage and have
                monthly fees.
              </p>
            </div>
            <div className="inline-flex rounded-md justify-center mt-4">
              <Link
                to="/medicare-supplements"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col px-1 sm:w-1/2 lg:w-1/3">
          <div className="flex flex-col flex-1 px-8 py-10 bg-white rounded-md shadow-md">
            <div className="flex-1">
              <h2 className="text-center text-xl uppercase font-bold">
                Medicare Advantage Plans
              </h2>
              <p className="mt-5 text-base text-center">
                Medicare Advantage Plans, sometimes referred to as Medicare Part
                C, replace Original Medicare but provide at least the same
                benefits. Depending on your location and eligibility, many plans
                are low/no cost and include extra benefits. Some plans include
                prescription drug coverage.
              </p>
            </div>
            <div className="inline-flex rounded-md justify-center mt-4 space-y-3">
              <Link
                to="/medicare-advantage"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col px-1 sm:w-1/2 lg:w-1/3">
          <div className="flex flex-col flex-1 px-8 py-10 bg-white rounded-md shadow-md">
            <div className="flex-1">
              <h2 className="text-center text-xl uppercase font-bold">
                Prescription Drug Plans
              </h2>
              <p className="mt-5 text-base text-center">
                Prescription Drug Plans, sometimes referred to as Medicare Part
                D, covers medication you receive from pharmacies. You may need
                these plans if you are on Original Medicare or a Medicare
                Supplement. There are different plans having different monthly
                premium, copays/coinsurance and formularies.
              </p>
            </div>

            {/* <Link
              to="/medicare-partd"
              className="w-full px-4 py-2 border border-transparent rounded-md shadow-sm uppercase text-2xl font-bold text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
            >
              Learn More
            </Link> */}
            <div className="inline-flex rounded-md justify-center mt-4">
              <Link
                to="/medicare-partd"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Features of Our Agency Section */}
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            We want to be your Medicare agent
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Medicare - most people will need it but few understand it. Why not
            put a trained, experienced, licensed professional to work for you.
            Together we can make sure you receive the benefits you have worked
            for all these years.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map(feature => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-400 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 uppercase text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    {/* About us explainer */}
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-0 lg:mt-0 lg:px-8 xl:mt-0">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">We are here to help</span>{" "}
                <span className="block text-indigo-600 xl:inline">you</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                From our office in Dallas, TX we can help people across the
                country with their Medicare plans by phone or video chat. Put
                our expertise to work for you today by scheduling an appointment
                or give us a call.
              </p>
              <p className="mt-3 text-4xl tracking-tight font-extrabold text-glate-700 sm:text-5xl md:text-6xl">
                (844)565-1957
              </p>
            </div>
          </main>
        </div>
      </div>
      {/* </div> */}
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <StaticImage
          src="../images/Womanphone_XL.jpg"
          as="div"
          className="h-56 w-full object-cover sm:h72 md:h-96 lg:w-full lg:h-full"
          //imgClassName=""
          loading="lazy"
          //layout="fullWidth"
          //apectRatio={16 / 9}
          //width={300}
          //quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
          //style={{ marginBottom: `1.45rem` }}
        />
      </div>
    </div>
    {/* CTA */}
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Ready to get professional advice?</span>
          <span className="block text-indigo-600">Contact us today.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/gethelp"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
            >
              Get help now
            </Link>
          </div>
          <div className="ml-3 infline-flex rounded-md shadow">
            <Link
              to="/schedule"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 hover:ring"
            >
              Schedule appointment
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default IndexPage
